<template>
  <div
    class="container-fluid padding-top-layout padding-right-layout padding-left-layout create-primary list-menu calculate_emissions"
  >
    <h1 class="page-title__text">{{ getTitleEmissionsPerOfProduct.text_title }}</h1>
    <div class="mt-12 text_explain">
      <p>{{ $t('calculate_supplier_products_emissions.explain_calculate') }}</p>
      <p>{{ $t('calculate_supplier_products_emissions.description_calculate') }}</p>
    </div>

    <div class="listMenus-btn">
      <v-row no-gutters class="form-input-primary">
        <v-col cols="12">
          <p class="title-block">
            {{ $t('calculate_supplier_products_emissions.calculate_your_organization_overall_emissions') }}
          </p>

          <div class="block-input">
            <p class="input-label">{{ $t('calculate_supplier_products_emissions.emissions_scope1') }}</p>

            <input-field
              :inputValue="formDataEmissions.emissionsScope1"
              :isNumberType="true"
              :inputICP="true"
              :label="$t('calculate_supplier_products_emissions.placeholder_enter_title')"
              class="input-text-field"
              @update:inputValue="handleUpdateProduct($event, 'emissionsScope1')"
            />
          </div>

          <div class="block-input mt-6">
            <p class="input-label">{{ $t('calculate_supplier_products_emissions.emissions_scope2') }}</p>
            <input-field
              :inputValue="formDataEmissions.emissionsScope2"
              :isNumberType="true"
              :inputICP="true"
              :label="$t('calculate_supplier_products_emissions.placeholder_enter_title')"
              @update:inputValue="handleUpdateProduct($event, 'emissionsScope2')"
            />
          </div>

          <div class="block-input mt-6">
            <p class="input-label">{{ $t('calculate_supplier_products_emissions.emissions_scope3') }}</p>
            <input-field
              :inputValue="formDataEmissions.emissionsScope3"
              :isNumberType="true"
              :inputICP="true"
              :label="$t('calculate_supplier_products_emissions.placeholder_enter_title')"
              class="input-text-field"
              @update:inputValue="handleUpdateProduct($event, 'emissionsScope3')"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="listMenus-btn boder-radius-none">
      <v-row no-gutters class="form-input-primary">
        <v-col cols="12">
          <p class="title-block">
            {{ $t('calculate_supplier_products_emissions.calculate_the_emissions_per_tissue') }}
          </p>
          <v-col cols="12">
            <div class="block-input">
              <p class="input-label">
                {{ $t('calculate_supplier_products_emissions.total_sales_of_the_organization') }}
              </p>
              <input-field
                :inputValue="formDataEmissions.totalSalesOrganization"
                :isNumberType="true"
                :inputICP="true"
                :label="$t('calculate_supplier_products_emissions.placeholder_enter_title')"
                class="input-text-field"
                @update:inputValue="handleUpdateProduct($event, 'totalSalesOrganization')"
              />
            </div>
          </v-col>
          <v-col cols="12">
            <div class="block-input mt-6">
              <p class="input-label">{{ getLabelSalesOfProduct.text_title }}</p>
              <input-field
                :inputValue="formDataEmissions.salesOfProduct"
                :isNumberType="true"
                :inputICP="true"
                :label="$t('calculate_supplier_products_emissions.placeholder_enter_title')"
                class="input-text-field"
                @update:inputValue="handleUpdateProduct($event, 'salesOfProduct')"
              />
            </div>
          </v-col>
          <v-col cols="12">
            <div class="block-input mt-6">
              <p class="input-label">{{ getLabelNumberOfUnitsOfProduct.text_title }}</p>
              <input-field
                :inputValue="formDataEmissions.numberUnitProduct"
                :isNumberType="true"
                :inputICP="true"
                :label="$t('calculate_supplier_products_emissions.placeholder_enter_title')"
                class="input-text-field"
                @update:inputValue="handleUpdateProduct($event, 'numberUnitProduct')"
              />
            </div>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <div class="listMenus-btn boder-radius-none text-block-calculate-emissions">
      <v-row no-gutters class="form-input-primary">
        <v-col cols="12">
          <div class="text-note">
            <div class="label_process_name">{{ getLabelTheAmountEmissionsPerProduct.text_title }}</div>
            <div class="text-block">
              <div class="text-calculate">{{ formatValueNumberFullData(calculateEmissionsPerUnitOfProduct) }}</div>
              <div class="text-unit">{{ $t('calculate_supplier_products_emissions.unit_product') }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="listMenus-btn boder-radius-none block-button-submit">
      <v-row no-gutters class="form-input-primary">
        <v-col cols="12">
          <common-button
            :label="$t('calculate_supplier_products_emissions.btn_submit_emissions')"
            type="colored"
            :disabled="isValidateFormEmissions || isValidateCalculateEmissions"
            class="submit-btn"
            @action="
              () => {
                dialogPopup = true;
              }
            "
          ></common-button> </v-col
      ></v-row>
    </div>
    <dialog-popup
      :dialog="dialogPopup"
      :message="$t('supplier.description_comfirm_send_data')"
      :loading="isLoadingSubmit"
      @submit="() => submitFormEmissions()"
      @close="dialogPopup = false"
    />
  </div>
</template>
<script>
import InputField from '@/components/products/input/InputField.vue';
import CommonButton from '@/components/utils/button.vue';
import { mapActions, mapState } from 'vuex';
import { submitDataSupplierCFPAction, infoFormPrimaryDataApi } from '@/api/supplier';
import { ROUTES } from '@/router/constants';
import DialogPopup from '@/components/dialogs/question-popup';
import { addThoundSandCommaWithTwoDigit, formatNumberByConditions } from '@/utils/convertNumber';
import { formatBigNumber, $_helper_isNumberType, math } from '@/concerns/newRegisterData/wijmo.helper';

export default {
  components: { InputField, CommonButton, DialogPopup },
  props: {},
  data() {
    return {
      formDataEmissions: {
        emissionsScope1: null,
        emissionsScope2: null,
        emissionsScope3: null,
        totalSalesOrganization: null,
        salesOfProduct: null,
        numberUnitProduct: null,
      },
      infoFormPrimaryData: {},
      processName: null,
      breadcrum: [
        {
          text: this.$t('supplier_products.title_emission_data_submissions_form'),
          disabled: false,
          href: ROUTES.SUPPLIER,
        },
        {
          text: this.$t('supplier_products.title_emission_register'),
          disabled: true,
        },
      ],
      dialogPopup: false,
      isLoadingSubmit: false,
    };
  },

  watch: {},
  async mounted() {
    this.updateBreadCrumb(this.breadcrum);
    await Promise.all([this.getInfoFormPrimaryDataApi()]);
  },
  computed: {
    ...mapState('supplier', ['supplierData', 'dataInfo']),

    calculateEmissionsPerUnitOfProduct() {
      const {
        emissionsScope1,
        emissionsScope2,
        emissionsScope3,
        totalSalesOrganization,
        salesOfProduct,
        numberUnitProduct,
      } = this.formDataEmissions;
      // Scope 3 no need to required
      if (
        $_helper_isNumberType(emissionsScope1) &&
        $_helper_isNumberType(emissionsScope2) &&
        $_helper_isNumberType(salesOfProduct) &&
        $_helper_isNumberType(totalSalesOrganization) &&
        $_helper_isNumberType(numberUnitProduct)
      ) {
        if (parseFloat(numberUnitProduct) <= 0 || parseFloat(totalSalesOrganization) === 0) {
          return '0';
        }
        const newNumberScope1 = math.bignumber(emissionsScope1);
        const newNumberScope2 = math.bignumber(emissionsScope2);
        const newNumberScope3 = math.bignumber(emissionsScope3 || 0);
        const newNumberSales = math.bignumber(salesOfProduct);
        const newNumberTotalSales = math.bignumber(totalSalesOrganization);
        const newNumberUnits = math.bignumber(numberUnitProduct);
        return formatBigNumber(
          math.evaluate(
            `(${newNumberScope1.toString()} + ${newNumberScope2.toString()} + ${newNumberScope3.toString()}) * ${newNumberSales.toString()} / ${newNumberTotalSales.toString()} / ${newNumberUnits.toString()}`,
          ),
          50,
        );
      }

      return null;
    },
    isValidateFormEmissions() {
      return Object.entries(this.formDataEmissions)
        .filter(([key]) => key !== 'emissionsScope3')
        .some(([, value]) => value === null || value === '');
    },
    getTitleEmissionsPerOfProduct() {
      return {
        text_title: this.$t('calculate_supplier_products_emissions.title_calculate', {
          processName: this.processName,
        }),
      };
    },
    getLabelSalesOfProduct() {
      return {
        text_title: this.$t('calculate_supplier_products_emissions.sales_of_product', {
          processName: this.processName,
        }),
      };
    },
    getLabelNumberOfUnitsOfProduct() {
      return {
        text_title: this.$t('calculate_supplier_products_emissions.number_of_units_of_product', {
          processName: this.processName,
        }),
      };
    },
    getLabelTheAmountEmissionsPerProduct() {
      return {
        text_title: this.$t('calculate_supplier_products_emissions.the_amount_of_emissions_per_product', {
          processName: this.processName,
        }),
      };
    },
    isValidateCalculateEmissions() {
      return this.calculateEmissionsPerUnitOfProduct === null || math.equal(this.calculateEmissionsPerUnitOfProduct, 0);
    },
  },

  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('supplier', ['setStatusDataSupplier']),
    async getInfoFormPrimaryDataApi() {
      await infoFormPrimaryDataApi(this.$route?.query?.primary_uuid)
        .then((res) => {
          this.infoFormPrimaryData = res.data;
          this.processName = res.data.process_name;
        })
        .catch(() => {
          const ROLE_SUPLIER = 6;
          if (res.user.role_id === ROLE_SUPLIER) {
            this.$router.push('/404');
          } else {
            this.$router.push({ path: ROUTES.TYPE_SELECTION });
          }
        });
    },
    async submitFormEmissions() {
      const data = this.formDataEmissions;
      const keyMapping = {
        emissionsScope1: 'emission_scope_1',
        emissionsScope2: 'emission_scope_2',
        emissionsScope3: 'emission_scope_3',
        totalSalesOrganization: 'total_sales',
        salesOfProduct: 'sales_revenue',
        numberUnitProduct: 'sales_quantity',
      };
      const formattedData = Object.keys(data).reduce((acc, key) => {
        if (keyMapping[key]) {
          acc[keyMapping[key]] = data[key];
        }
        return acc;
      }, {});
      formattedData.emissions_per_unit = this.calculateEmissionsPerUnitOfProduct;
      const dataSubmit = {
        action: 'submit',
        data: formattedData,
        dataWorkflow: {
          uuid_primary_data: this.infoFormPrimaryData.uuid,
          note: this.dataInfo.note,
        },
      };

      try {
        await submitDataSupplierCFPAction(dataSubmit);
        this.setStatusDataSupplier(true);
        this.$router.push({
          path: ROUTES.SUPPLIER,
          query: { primary_uuid: this.$route?.query?.primary_uuid },
        });
      } catch (error) {
        console.log(error, 'error');
      }
    },
    formatValueNumberFullData(number) {
      if (!number) return '---';
      if (number === '0') return '0.00';
      return formatNumberByConditions(
        addThoundSandCommaWithTwoDigit(formatBigNumber(number, 50).replace(/[^0-9\.\-]+/g, ''), '', false, true),
        {
          isRealNumber: true,
          isGetMoreZeroInDecimalPart: true,
        },
      );
    },
    handleUpdateProduct(newData, keyData) {
      if (newData !== this.formDataEmissions[keyData]) {
        this.formDataEmissions[keyData] = newData;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/views/products/linkToPrimaryData/styles/index.scss';

.calculate_emissions {
  .page-title__text {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: $goldMid2;
  }

  .text_explain {
    color: $monoBlack;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 5%;
    padding-bottom: 32px;
    margin-bottom: 0px;

    p:first-child {
      margin-bottom: 8px;
    }
  }

  .listMenus-btn {
    position: relative;
    border-bottom: 1px solid rgba(42, 42, 48, 0.1);

    .form-input-primary {
      .title-block {
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 28px;
        margin-bottom: 24px;
        letter-spacing: 0.05em;
        color: $monoBlack;
      }

      .block-input {
        .input-label {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.03em;
          color: $monoBlack;
          margin-bottom: 8px;
        }

        .input-text-field {
          width: 100%;
        }
      }

      .text-note {
        color: $monoBlack;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.1em;
        border: 1px solid $goldLight;
        padding: 16px 32px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        word-break: normal;
        overflow-wrap: anywhere;
        white-space: normal;
        .label_process_name {
          white-space: nowrap;
        }
        .text-block {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .text-calculate {
            margin: 0 2px;
            white-space: nowrap;
          }
          .text-unit {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .text-block-calculate-emissions {
    padding: 24px 32px;
  }

  .block-button-submit {
    padding: 32px 32px;
    border: none;
    border-radius: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    .submit-btn {
      display: block;
      margin: auto;
      width: 100%;
    }
  }
}
</style>
